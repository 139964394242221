<template>
  <div class="">
    <el-form class="form-inline" :inline="true">
      <el-form-item>
        <el-upload
                ref="upload"
                :action="uploadUrl"
                name="file"
                :data="picdata"
                :show-file-list="false"
                :multiple="true"
                :headers="headers"
                :before-upload="beforeUpload"
                :on-success="uploadSuccess"
                :on-error="uploadError">
          <el-button icon="el-icon-upload2">上传图片</el-button>
        </el-upload>
      </el-form-item>
      <el-form-item>
        <el-input placeholder="请输入" class="input-with-select" clearable v-model="search.keywords">
          <el-button slot="append" @click="handleSearch()">搜索</el-button>
        </el-input>
      </el-form-item>
    </el-form>
    <div class="picList mg-tp-20">
      <ul>
        <li v-for="(item, index) in dataList" v-bind:key="index" @click="chooseFile(item)" :class="{ 'active': item.active}">
          <div class="image-img">
            <img :src="item.pic">
            <div class="image-meta">{{item.title}}</div>
            <span class="spot"><i class="el-icon-check"></i></span>
          </div>
        </li>
      </ul>
    </div>
    <el-pagination
        background
        @current-change="handleCurrentChange"
        layout="total, prev, pager, next, jumper"
        :current-page.sync="search.current_page"
        :page-size="27"
        :total="search.total">
    </el-pagination>
    <el-button type="primary" size="small" @click="chooseOk()" style="position: absolute; bottom: 35px; right: 30px;">确定</el-button>
  </div>
</template>

<script>
  import config from "../config/config";
  import {getToken} from "../utils/auth";

  export default {
    data() {
      return {
        uploadUrl: config.HTTP_REQUEST_URL + 'admin/1.0/common/uploadimg',
        headers: {
          authorization: 'Bearer ' + getToken()
        },
        picdata: {
          type: 'file'
        },
        dataList: false,
        search: {
          current_page: 1,
          total: 0,
          limit: 21,
          keywords: ''
        },
        chooseList: [],
        fileNum: this.chooseNum
      }
    },
    props: {
      chooseNum: Number,
      chooseFunc: Function
    },
    created() {
      this.chooseList = []
      this.search.current_page = 1
      this.search.keywords = ''
    },
    mounted() {
      this.getList()
    },
    methods: {
      getList() {
        var that = this
        that.showLoading()
        var param = {
          page: that.search.current_page,
          page_size: that.search.limit,
          keywords: that.search.keywords
        }
        this.$api.merchant.fileList(param, function (res) {
          that.hideLoading()
          if(res.errcode == 0) {
            that.dataList = res.data.data
            that.initChooseActive()
            that.search.current_page = res.data.current_page
            that.search.total = res.data.total
          } else {
            that.fail(res.errmsg)
          }
        })
      },
      initChooseActive() {
        var that = this
        that.dataList = that.dataList.filter(function (item) {
          item.active = false
          if(!that.isEmpty(that.chooseList)) {
            that.chooseList.forEach(function (child) {
              if(child.id == item.id) {
                item.active = true
              }
            })
          }
          return true
        })
      },
      handleSearch() {
        this.search.current_page = 1
        this.getList()
      },
      beforeUpload(file) {
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';
        const isLt2M = file.size / 1024 / 1024 < 1;
        if (!isJPG) {
          this.$message.error('上传头像图片只能是 JPG 格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 1MB!');
        }
        return isJPG && isLt2M;
      },
      uploadSuccess(res, file) {
        var that = this
        if(res.errcode == 0) {
          that.success('上传成功')
          that.search.current_page = 1
          that.getList()
        } else {
          that.$message.error(res.errmsg)
        }
      },
      uploadError(res, file) {
        this.$message.error('上传失败，请重新上传!');
      },
      handleCurrentChange(val) {
        this.chooseList = []
        this.search.current_page = val
        this.getList()
      },
      chooseFile(obj) {
        var that = this
        if(that.fileNum == 1) {
          that.chooseList = [obj]
        } else {
          var isAdd = true
          that.chooseList = that.chooseList.filter(function (item) {
            if(item.id == obj.id) {
              isAdd = false
              return false
            } else {
              return true
            }
          })
          if(isAdd) {
            if(that.chooseList.length >= that.fileNum) {
              this.$message.error('最多只能选择'+that.fileNum+'张!');
              return false
            }
            that.chooseList.push(obj)
          }
        }
        this.initChooseActive()
      },
      chooseOk() {
        if(this.isEmpty(this.chooseList)) {
          this.$message.error('请选择图片!')
        } else {
          if(this.fileNum == 1) {
            this.chooseFunc(this.chooseList[0])
          } else {
            this.chooseFunc(this.chooseList)
          }
        }
      }
    }
  }
</script>

<style scoped>

</style>
